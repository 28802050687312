export const LIMIT = 10;
export const SUPER_ADMIN = "super_admin";
export const STORE_OWNER = "store_owner";
export const STAFF = "staff";
export const TOKEN = "token";
export const PERMISSIONS = "permissions";
export const AUTH_CRED = "AUTH_CRED";
export const CART_KEY = "pick-cart";
export const CHECKOUT = "pickbazar-checkout";

export const EDITOR = "editor";
export const QC = "qc";
export const MANAGER = "manager";
export const PHOTOGRAPHER = "photographer";
