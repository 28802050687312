import React, { useState } from 'react'
import MapAutoComplete from "./map/mapAutocomplete";


export  function Map() {
    const [mapAddres, setMapAddres] = useState("");
  
    const setValue = (key, value) => {
      console.log(`Setting ${key} to ${value}`);
    };
   
  
    return (
      <MapAutoComplete
        setMapAddres={setMapAddres}
        mapAddres={mapAddres}
        setValue={setValue}
      />
    );
  }