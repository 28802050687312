import { useMutation, useQueryClient } from "react-query";
import OrderStatus from "@repositories/order-status";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import http from "@utils/api/http";


export const useDeleteOrderStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => {
      console.log('Mutation: Starting delete for id:', id);
      try {
        const response = await OrderStatus.delete(`${API_ENDPOINTS.ORDER_STATUS}/${id}`);
        console.log('Mutation: Delete response:', response);
        
        if (response.status >= 400) {
          console.log('Mutation: Error status detected');
          throw new Error(response.data.message || 'An error occurred');
        }
        console.log('Mutation: Successful delete');
        return response.data.message;
      } catch (error) {
        console.error('Mutation: Error in delete:', error);
        if (error instanceof Error) {
          throw new Error(error.message || 'An error occurred while deleting the order status');
        }
        throw new Error('An unexpected error occurred');
      }
    },
    {
      onSettled: (data, error, variables) => {
        console.log('Mutation: onSettled called', { data, error, variables });
        queryClient.invalidateQueries(API_ENDPOINTS.ORDER_STATUS);
      },
    }
  );
};