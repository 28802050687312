import { useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import Loader from '@components/ui/loader/loader';
 const libraries = ['places'];  // this ensure that we uses places library from google maps api


 const MapAutoComplete = ({ setMapAddres, mapAddres, defaultValues }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
    libraries,
  });
  console.log('map defaulvalues', typeof(defaultValues)  )


  const handlePlaceSelect = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
      let street_number = '';
      let route = '';
      let country = '';
      let city = '';
      let sublocality = '';
      let state = '';
      let zip = '';
      let neighborhood = '';
      let subpremise = '';

      for (const component of addressComponents) {
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number":
            street_number = component.long_name;
            break;
          case "route":
            route = component.long_name;
            break;
          case "country":
            country = component.long_name;
            break;
          case "locality":
            city = component.long_name;
            break;
          case "sublocality_level_1":
            sublocality = component.long_name;
            break;
          case "administrative_area_level_1":
            state = component.long_name;
            break;
          case "postal_code":
            zip = component.long_name;
            break;
          case "neighborhood":
            neighborhood = component.long_name;
            break;
          case "subpremise":
            subpremise = component.long_name;
            break;
        }
      }

      // If city is empty, fall back to sublocality_level_1
      if (!city && sublocality) {
        city = sublocality;
      }

      // If city is still empty, try to extract it from the formatted address
      if (!city) {
        const addressParts = place.formatted_address.split(',');
        if (addressParts.length >= 3) {
          city = addressParts[addressParts.length - 3].trim();
        }
      }

      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        formattedAddress: place.formatted_address,
        country,
        city,
        state,
        zip,
        street_address: `${street_number} ${route}`.trim(),
        neighborhood,
        subpremise,
        levels: {
          country,
          administrative_area_level_1: state,
          locality: city,
          sublocality_level_1: sublocality,
          neighborhood,
          route,
          street_number,
          subpremise,
        }
      };

      console.log('map address', location);
      setMapAddres(location);
    } else {
      console.error('Autocomplete is not loaded yet!');
    }
  };

  console.log('address type map address', mapAddres?.mapAddres?.formattedAddress);

  return (
    <div className="w-full">
      {isLoaded ? (
        <Autocomplete
          onLoad={(autocomplete) => setAutocomplete(autocomplete)}
          onPlaceChanged={handlePlaceSelect}
        >
          <input
            type="text"
            placeholder="Enter your address"
            className="px-4 h-12 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent"
            defaultValue={ typeof(defaultValues) == 'undefined' ? '' : mapAddres?.mapAddres?.formattedAddress}
            autoComplete="off"
            key={"new"}
            autoCorrect="off"
            autoCapitalize="off"
          />
        </Autocomplete>
      ) : (
        <div><Loader /></div>
      )}
    </div>
  );
};

export default MapAutoComplete;