import http from "@utils/api/http";

export default class Base<C, U> {
  http = async <T>(
    url: string,
    type: string,
    variables: T | null = null,
    options?: any
  ) => {
    console.log(`Base class: Calling ${type.toUpperCase()} ${url}`);
    try {
      const response = await (http as any)[type](url, variables, options);
      console.log(`Base class: Response from ${type.toUpperCase()} ${url}:`, response);
      return response;
    } catch (error) {
      console.error(`Base class: Error in ${type.toUpperCase()} ${url}:`, error);
      throw error;
    }
  };

  all = async (url: string) => {
    return this.http(url, "get");
  };

  find = async (url: string) => {
    return this.http(url, "get");
  };

  create = async (url: string, variables: C) => {
    return this.http<C>(url, "post", variables);
  };

  update = async (url: string, variables: U) => {
    return this.http<U>(url, "put", variables);
  };

  delete = async (url: string) => {
    console.log('Base class: Attempting to delete:', url);
    try {
      const response = await this.http(url, "delete");
      console.log('Base class: Delete response:', response);
      return response;
    } catch (error) {
      console.error('Base class: Error in delete:', error);
      throw error;
    }
  };
  
}
