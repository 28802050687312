import ConfirmationCard from "@components/common/confirmation-card";
import {
  useModalAction,
  useModalState,
} from "@components/ui/modal/modal.context";
import { useDeleteOrderStatusMutation } from "@data/order-status/order-status-delete.mutation";
import { toast } from 'react-toastify';



const OrderStatusDeleteView = () => {
  const { mutateAsync: deleteOrderStatusByID, isLoading: loading } = useDeleteOrderStatusMutation();
  const { data } = useModalState();
  const { closeModal } = useModalAction();

  async function handleDelete() {
    console.log('Component: handleDelete called');
    try {
      const message = await deleteOrderStatusByID(data);
      console.log('Component: Delete successful, message:', message);
      closeModal();
      toast.success(message || 'Order status deleted successfully');
    } catch (error) {
      console.error('Component: Error in handleDelete:', error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    }
  }

  return (
    <ConfirmationCard
      onCancel={closeModal}
      onDelete={handleDelete}
      deleteBtnLoading={loading}
    />
  );
};

export default OrderStatusDeleteView;
