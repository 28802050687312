export const ROUTES = {
  DASHBOARD: "/",
  SHOPS: "/team-workspace",
  COMPANY: "/company",
  COMPANYUSER: "/companyUser",
  ADMIN_MY_SHOPS: "/my-shops",
  CREATE_SHOP: "/shops/create",
  LOGIN: "/login",
  ORDER_STATUS: "/order-status",
  ORDERS: "/orders",
  CREATE_ORDER: "/orders/create",
  PRODUCTS: "/products",
  COUPONS: "/coupons",
  VERIFY_COUPONS: "/coupons/verify",
  USERS: "/users",
  TAXES: "/taxes",
  SHIPPINGS: "/shippings",
  SETTINGS: "/settings",
  STORE_SETTINGS: "/vendor/settings",
  STORE_KEEPER: "/vendor/store_keepers",
  CATEGORIES: "/categories",
  ATTRIBUTES: "/attributes",
  ATTRIBUTE_VALUES: "/attribute-values",
  GROUPS: "/layout",
  TAGS: "/tags",
  WITHDRAWS: "/withdraws",
  PROFILE_UPDATE: "/profile-update",
  LOGOUT: "/logout",
  STAFFS: "/teams",
  REFUNDS: "/refunds",
  AUTHORS: "/authors",
  MANUFACTURERS: "/manufacturers",
  CHECKOUT: "/orders/checkout",
  TEAMS: "/staffs",
  PHOTOS_PACKAGE: "/photos-package",
  VIRTUAL_TOUR: "/virtual-tour",
  INVOICE: "/invoice",
  WORK_QUEUE: "/work-queue",
  UPLOAD_EDITED_IMAGES: "/upload-edited-images",
  EARNINGS: "/earnings",
  PROFILE: "/profile",
  COMMUNICATION: "/communication",
  QUALITY_CHECK_QUEUE: "/quality-check-queue",
  APPROVED_IMAGES: "/approved-images",
  REJECTED_IMAGES: "/rejected-images",
  OPEN_JOBS: "/open-jobs",
  UPLOAD_IMAGES: "/upload-images",
  SCHEDULE: "/schedule",
  SERVICES: "/services",
  SERVICE_DETAILS: "/services/:serviceId",
  BOOKINGS: "/bookings",
  BOOKING_DETAILS: "/bookings",
  ORDER_DETAILS: "/orders",
  STAFF_MANAGEMENT: "/staff-management",
  COMPANY_SETTINGS: "/company-settings",
  REPORTS: "/reports",

  MANAGER_DASHBOARD: "/",
  ORDERS_MANAGEMENT: "order-management",
  PHOTOGRAPHER_MANAGEMENT: "photographer-management",
  EDITOR_MANAGEMENT: "editor-management",
  QUALITY_CONTROL: "quality control",

  AGENTS : '/agents',
};
