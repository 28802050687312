import Modal from "@components/ui/modal/modal";
import dynamic from "next/dynamic";
import { MODAL_VIEWS, useModalAction, useModalState } from "./modal.context";
import OrderStatusDeleteView from "@components/order-status/order-status-delete-view";
import { Map } from "@components/map";
 
const TagDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/tag/tag-delete-view")
);
const TaxDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/tax/tax-delete-view")
);
const BanCustomerView = dynamic<React.ComponentType<any>>(
  () => import("@components/user/user-ban-view")
);
const UserWalletPointsAddView = dynamic<React.ComponentType<any>>(
  () => import("@components/user/user-wallet-points-add-view")
);
const MakeAdminView = dynamic<React.ComponentType<any>>(
  () => import("@components/user/make-admin-view")
);
const ShippingDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/shipping/shipping-delete-view")
);
const AddressDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/address/delete-view")
);
const CategoryDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/category/category-delete-view")
);
const CouponDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/coupon/coupon-delete-view")
);
const ProductDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/product/product-delete-view")
);
const TypeDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/group/group-delete-view")
);
const AttributeDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/attribute/attribute-delete-view")
);
const ApproveShopView = dynamic<React.ComponentType<any>>(
  () => import("@components/shop/approve-shop-view")
);
const DisApproveShopView = dynamic<React.ComponentType<any>>(
  () => import("@components/shop/disapprove-shop-view")
);
const RemoveStaffView = dynamic<React.ComponentType<any>>(
  () => import("@components/shop/staff-delete-view")
);
const ExportImportView = dynamic<React.ComponentType<any>>(
  () => import("@components/product/import-export-modal")
);
const AttributeExportImport = dynamic<React.ComponentType<any>>(
  () => import("@components/attribute/attribute-import-export")
);
const UpdateRefundConfirmationView = dynamic<React.ComponentType<any>>(
  () => import("@components/refund/refund-confirmation-view")
);
const RefundImageModal = dynamic<React.ComponentType<any>>(
  () => import("@components/refund/refund-image-modal")
);
const CreateOrUpdateAddressForm = dynamic<React.ComponentType<any>>(
  () => import("@components/address/create-or-update")
);
const AddOrUpdateCheckoutContact = dynamic<React.ComponentType<any>>(
  () => import("@components/checkout/contact/add-or-update")
);
const SelectCustomer = dynamic<React.ComponentType<any>>(
  () => import("@components/checkout/customer/select-customer")
);
const AuthorDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/author/author-delete-view")
);
const ManufacturerDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/manufacturer/manufacturer-delete-view")
);
const ProductVariation = dynamic<React.ComponentType<{ productSlug: string }>>(
  () => import("@components/product/variation/variation")
);
const CompanyDeleteView = dynamic<React.ComponentType<any>>(
  () => import("@components/company/company-delete-view")
);

function renderModal(
  view: MODAL_VIEWS | undefined,
  data: any
): React.ReactNode {
  switch (view) {
    case "DELETE_PRODUCT":
      return <ProductDeleteView />;
    case "DELETE_TYPE":
      return <TypeDeleteView />;
    case "DELETE_ATTRIBUTE":
      return <AttributeDeleteView />;
      case "DELETE_STATUS":
        return <OrderStatusDeleteView />;
    case "DELETE_CATEGORY":
      return <CategoryDeleteView />;
    case "DELETE_COUPON":
      return <CouponDeleteView />;
    case "DELETE_TAX":
      return <TaxDeleteView />;
    case "DELETE_SHIPPING":
      return <ShippingDeleteView />;

      case "DELETE_VIEW":
      return <AddressDeleteView />;
       
    case "DELETE_TAG":
      return <TagDeleteView />;
    case "DELETE_COMPANY":
      return <CompanyDeleteView />;
    case "DELETE_MANUFACTURER":
      return <ManufacturerDeleteView />;
    case "DELETE_AUTHOR":
      return <AuthorDeleteView />;
    case "BAN_CUSTOMER":
      return <BanCustomerView />;
    case "SHOP_APPROVE_VIEW":
      return <ApproveShopView />;
    case "SHOP_DISAPPROVE_VIEW":
      return <DisApproveShopView />;
    case "DELETE_STAFF":
      return <RemoveStaffView />;
    case "UPDATE_REFUND":
      return <UpdateRefundConfirmationView />;
    case "ADD_OR_UPDATE_ADDRESS": 
      return <CreateOrUpdateAddressForm />;
    case "ADD_OR_UPDATE_CHECKOUT_CONTACT":
      return <AddOrUpdateCheckoutContact />;
    case "REFUND_IMAGE_POPOVER":
      return <RefundImageModal />;
    case "MAKE_ADMIN":
      return <MakeAdminView />;
    case "EXPORT_IMPORT_PRODUCT":
      return <ExportImportView />;
    case "EXPORT_IMPORT_ATTRIBUTE":
      return <AttributeExportImport />;
    case "ADD_WALLET_POINTS":
      return <UserWalletPointsAddView />;
    case "SELECT_PRODUCT_VARIATION":
      return <ProductVariation productSlug={data} />;
    case "SELECT_CUSTOMER":
      return <SelectCustomer />;

      case 'OPEN_MAP': return <Map   />
    default:
      return null;
  }
}

const ManagedModal: React.FC = () => {
  const { isOpen, view, data } = useModalState();
  const { closeModal } = useModalAction();

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {renderModal(view, data)}
    </Modal>
  );
};

export default ManagedModal;
