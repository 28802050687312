export const API_ENDPOINTS = {
  ATTACHMENTS: "attachments",
  ANALYTICS: "analytics",
  ATTRIBUTES: "attributes",
  ATTRIBUTE_VALUES: "attribute-values",
  ORDER_STATUS: "order-status",
  ORDERS: "orders",
  USERS: "users",
  REGISTER: "register",
  PRODUCTS: "products",
  POPULAR_PRODUCTS: "popular-products",
  COUPONS: "coupons",
  CUSTOMERS: "customers",
  TAXES: "taxes",
  SHIPPINGS: "shippings",
  SETTINGS: "settings",
  CATEGORIES: "categories",
  TAGS: "tags",
  TYPES: "types",
  PROFILE_UPDATE: "profile-update",
  LOGOUT: "logout",
  ME: "me",
  TOKEN: "token",
  BLOCK_USER: "users/block-user",
  UNBLOCK_USER: "users/unblock-user",
  CHANGE_PASSWORD: "change-password",
  FORGET_PASSWORD: "forget-password",
  VERIFY_FORGET_PASSWORD_TOKEN: "verify-forget-password-token",
  RESET_PASSWORD: "reset-password",
  DOWNLOAD_INVOICE: "download/invoice",
  APPROVE_SHOP: "approve-shop",
  DISAPPROVE_SHOP: "disapprove-shop",
  SHOPS: "shops",
  MY_SHOPS: "my-shops",
  WITHDRAWS: "withdraws",
  APPROVE_WITHDRAW: "approve-withdraw",
  ADD_WALLET_POINTS: "add-points",
  REFUNDS: "refunds",
  STAFFS: "staffs",
  ADD_STAFF: "staffs",
  UPDATE_STAFF: 'staff',
  REMOVE_STAFF: "staffs",
  IMPORT_PRODUCTS: "import-products/",
  IMPORT_ATTRIBUTES: "import-attributes/",
  IMPORT_VARIATION_OPTIONS: "import-variation-options/",
  MAKE_ADMIN: "users/make-admin",
  AUTHORS: "authors",
  MANUFACTURERS: "manufacturers",
  CHECKOUT: "orders/checkout/verify",
  VIRTUAL_TOUR: "virtual-tour",
  FORM2: "form2",
  USERS_ADDRESS: '/address',


  ORDER_ASSIGNMENTS: "order-assignments",
  TEAM_USER: "team-user",
  TEAM_ROLES: "team-roles",
  COMPANY: "company",
  DELETE_COMPANY: "company",
  COMPANYALTER: "companyalter",
  JOB: "job",
  JOBS: "jobs",
  RAWFILES: 'rawfiles'
};
